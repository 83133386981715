.container {
  padding: 40px 18px;
}

.boldItalicFont {
  font-family: 'Kazimir Bold Italic' !important;
}

.helloText {
  text-align: center;
  margin-top: 22px;
}

.inYouBalanceText {
  font-size: 18px !important;
}

.balanceText {
  text-align: center;
  margin-bottom: 100px !important;
}