@font-face {
  font-family: 'Kazimir Lignt';
  src: url("Kazimir Light.otf");
  font-weight: 400;
}

@font-face {
  font-family: 'Kazimir Bold Italic';
  src: url("Kazimir Bold Italic.otf");
  font-weight: 500;
}

@font-face {
  font-family: 'Kazimir Bold';
  src: url("Kazimir Bold.otf");
  font-weight: 600;
}

@font-face {
  font-family: 'Kazimir Italic';
  src: url("Kazimir Italic.otf");
  font-weight: 500;
}

@font-face {
  font-family: 'Kazimir Lignt Italic';
  src: url('Kazimir Light Italic.otf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Kazimir Medium';
  src: url('Kazimir Medium.otf');
  font-style: normal;
  font-weight: normal;
}