.lightTitle {
  font-size: 24px;
  font-family: 'Kazimir Lignt' !important;
  line-height: 24px;
  margin-top: 40px;
}

.title {
  font-family: 'Kazimir Bold Italic' !important;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin-top: 15px;
}

.description {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  font-family: 'Kazimir Lignt' !important;
}