@import "uiKit/fonts/kazimir/kazimir";

body {
  background-color: #F3F3EF !important;
}

.App {
  max-width: 500px;
  margin: 0 auto;
}
